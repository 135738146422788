import { Box, Button, Card, Chip, Divider, Link, Stack, Typography } from '@mui/material';
import { without } from 'lodash-es';
import React, { useMemo } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { ReefRecommendationStatus } from '../../../../graphql/generated';
import type {
  GrowthRecommendation,
  GrowthRecommendationFields,
} from '../../../../models/recommendation';
import { GROWTH_TYPE_LABELS } from '../../../../models/recommendation';
import { userWork$ } from '../../../../selectors';
import { bottom } from '../../../../utils';
import { CreateSprintButton } from './CreateSprintButton';
import { GrowthRecommendationField } from './GrowthRecommendationField';

const ALL_GROWTH_REC_FIELDS: Array<keyof GrowthRecommendationFields> = [
  'currentPipeline',
  'currentScore',
  'ownerEmail',
  'secondaryOwnerEmail',
  'renewalDate',
  'products',
  'currentPredictedOverage',
  'customerStartedOn',
  'createdOn',
];
type GrowthRecommendationItemProps = GrowthRecommendation;
export const GrowthRecommendationItem = ({
  id,
  createdOn,
  title,
  account,
  metrics,
  playbook,
  sprint,
  status,
  type,
}: GrowthRecommendationItemProps) => {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const [params] = useSearchParams();
  const showPreview = useMemo(() => params.get('preview') != null, [params]);
  const hideActionButton = true; // TODO: display button again once it is updated according to the new design [ENG-4346]
  const viewSprintLink = useMemo(() => (sprint != null ? `/sprint/${sprint.id}` : null), [sprint]);
  const SprintButton = useMemo(() => {
    switch (status) {
      case ReefRecommendationStatus.Accepted:
        return viewSprintLink != null ? (
          <Link component={RouterLink} to={viewSprintLink}>
            <Button
              variant="outlined"
              fullWidth
              data-uid={userWork$.recommendations.viewSprintBtn(id)}
            >
              View Sprint
            </Button>
          </Link>
        ) : (
          <Button
            variant="outlined"
            color="warning"
            fullWidth
            disabled
            data-uid={userWork$.recommendations.missingSprintBtn(id)}
          >
            Missing Sprint
          </Button>
        );
      case ReefRecommendationStatus.NoActionTaken:
        return (
          <CreateSprintButton
            accountId={account.id}
            accountName={account.name}
            recommendationId={id}
            playbookId={playbook.id}
            playbookTitle={playbook.title}
            showDemoData={showDemoData}
          />
        );
      case ReefRecommendationStatus.Rejected:
        return (
          <Button
            variant="outlined"
            fullWidth
            color="info"
            data-uid={userWork$.recommendations.rejectedSprintBtn(id)}
          >
            No Sprint
          </Button>
        );
      default:
        bottom(status);
    }
  }, [
    id,
    status,
    viewSprintLink,
    account.id,
    account.name,
    playbook.id,
    playbook.title,
    showDemoData,
  ]);
  const chipInfo = useMemo(
    () => ({
      label: GROWTH_TYPE_LABELS[type] ?? 'Growth',
      backgroundColor: 'rgba(25, 118, 210, 0.30)',
    }),
    [type],
  );
  const recFields = useMemo(() => {
    switch (type) {
      case 'PRODUCT_UPSELL':
        return ALL_GROWTH_REC_FIELDS;
      default:
        return without(ALL_GROWTH_REC_FIELDS, 'currentPredictedOverage');
    }
  }, [type]);
  const fieldsData = useMemo<GrowthRecommendationFields>(
    () => ({
      ...account,
      ...metrics,
      createdOn,
      lastUpsellDate: null,
    }),
    [account, createdOn, metrics],
  );
  return (
    <Card
      sx={{
        maxWidth: '60rem',
        '& p': {
          fontFamily: 'Inter',
        },
        overflow: 'visible',
        borderColor: chipInfo.backgroundColor,
      }}
      variant="outlined"
      data-uid={userWork$.recommendations.item(id)}
      data-rec-id={id}
      data-rec-status={status}
      data-rec-sprint-id={sprint?.id}
      data-rec-created-on={createdOn}
      data-rec-growth-score={metrics.currentScore?.value}
    >
      <Stack direction="column" spacing={1} sx={{ p: 4 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="column" spacing={0.25} flexGrow={1} sx={{ mt: -1 }}>
            <Box pb={0.25}>
              <Chip label={chipInfo.label} sx={{ backgroundColor: chipInfo.backgroundColor }} />
            </Box>
            <Typography
              sx={{
                fontSize: '1.375rem',
                maxWidth: '45rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              data-uid={userWork$.recommendations.name}
              title={title}
            >
              {title}
            </Typography>
            <Link
              component={RouterLink}
              to={`/customer-details/${account.id}`}
              data-uid={userWork$.recommendations.accountLink(id)}
              sx={{
                maxWidth: '45rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: '1.125rem',
                  fontFamily: 'Inter',
                }}
                title={account.name}
              >
                {account.name}
              </Typography>
            </Link>
          </Stack>
          {!hideActionButton && !showPreview && SprintButton}
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: chipInfo.backgroundColor }} />
      {/* FIXME: use a grid layout? */}
      <Stack spacing={3} sx={{ p: 4 }}>
        <Stack direction="row" spacing={2.5}>
          {recFields.slice(0, 5).map((rf) => (
            <Box key={`${id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
              <GrowthRecommendationField
                key={`${id}-${rf}-field`}
                recId={id}
                field={rf}
                data={fieldsData}
              />
            </Box>
          ))}
        </Stack>
        <Stack direction="row" spacing={2.5}>
          {recFields.slice(5).map((rf) => (
            <Box key={`${id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
              <GrowthRecommendationField
                key={`${id}-${rf}-field`}
                recId={id}
                field={rf}
                data={fieldsData}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};
