import type { ApolloError, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { differenceInSeconds } from 'date-fns';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

import type {
  CrossSellGrowthRecommendationFieldsFragment,
  FullChurnRiskRecommendationFieldsFragment,
  GetClientGrowthRecommendationsQuery,
  GetClientRiskRecommendationsQuery,
  PartialChurnRiskRecommendationFieldsFragment,
} from '../graphql/generated';
import {
  GetClientRiskRecommendationsDocument,
  GetMyWorkRecommendationsDocument,
} from '../graphql/generated';
import { transformBaseAccount, transformBaseContract } from '../helpers/recommendations';
import type { GrowthRecommendation, RiskRecommendation } from '../models/recommendation';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import { asUUID } from '../models/uuid';
import { transformDate } from '../utils';

const transformFullChurnRiskRecommendation = <T extends FullChurnRiskRecommendationFieldsFragment>(
  item: T,
): RiskRecommendation => ({
  account: {
    current: transformBaseAccount(item.account.current),
    original: transformBaseAccount(item.account.original),
  },
  // Contract data is never available for full churn recommendations
  contract: {
    current: null,
    original: null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current?.bucket ?? null,
          category: item.recommendationScore.current?.category ?? null,
          type: 'ACCOUNT_FULL_CHURN_RISK',
          value: item.recommendationScore.current?.value ?? null,
          color: item.recommendationScore.current?.color ?? null,
        }
      : null,
    originalScore: item.recommendationScore.original
      ? {
          bucket: item.recommendationScore.original?.bucket ?? null,
          category: item.recommendationScore.original?.category ?? null,
          type: 'ACCOUNT_FULL_CHURN_RISK',
          value: item.recommendationScore.original?.value ?? null,
          color: item.recommendationScore.original?.color ?? null,
        }
      : null,
    currentRelevantArr: item.relevantArr.current, // TODO: multi-currency conversion
    originalRelevantArr: item.relevantArr.original, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'FULL_CHURN_CUSTOMER',
});
const transformPartialChurnRiskRecommendation = <
  T extends PartialChurnRiskRecommendationFieldsFragment,
>(
  item: T,
): RiskRecommendation => ({
  account: {
    current: transformBaseAccount(item.account.current),
    original: transformBaseAccount(item.account.original),
  },
  contract: {
    current: transformBaseContract(item.contract.current, item.account.current?.id),
    original: transformBaseContract(item.contract.original, item.account.current?.id),
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket,
          category: item.recommendationScore.current.category,
          type: 'CONTRACT_USAGE_RATING',
          value: item.recommendationScore.current.value,
          color: item.recommendationScore.current.color,
        }
      : null,
    originalScore: item.recommendationScore.original
      ? {
          bucket: item.recommendationScore.original?.bucket ?? null,
          category: item.recommendationScore.original?.category ?? null,
          type: 'CONTRACT_USAGE_RATING',
          value: item.recommendationScore.original?.value ?? null,
          color: item.recommendationScore.original?.color ?? null,
        }
      : null,
    currentRelevantArr: item.relevantArr.current, // TODO: multi-currency conversion
    originalRelevantArr: item.relevantArr.original, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PARTIAL_CHURN_CONTRACT',
});

const transformCrossSellGrowthRecommendation = <
  T extends CrossSellGrowthRecommendationFieldsFragment,
>(
  item: T,
): GrowthRecommendation => ({
  account: {
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    customerStartedOn: transformDate(item.account.current?.customerStartedOn ?? null),
    rawPipeline: item.account.current?.rawPipeline ?? null,
    renewalDate: transformDate(item.account.current?.renewalDate ?? null),
    secondaryOwnerEmail: item.account.current?.roles.secondaryOwner?.email ?? null,
    secondaryOwnerName: item.account.current?.roles.secondaryOwner?.name ?? null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentPipeline: null,
    currentPredictedOverage: null,
    currentPredictedOverageDate: null,
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket ?? null,
          category: item.recommendationScore.current.category ?? null,
          type: 'UNKNOWN',
          value: item.recommendationScore.current.value ?? null,
          color: item.recommendationScore.current.color ?? null,
        }
      : null,
    originalPipeline: null,
    originalPredictedOverage: null,
    originalPredictedOverageDate: null,
    originalScore: null,
    products: item.product.current ? [item.product.current] : [],
  },
  owner:
    item.owner != null
      ? {
          email: item.owner.email,
          id: item.owner.id,
          name: item.owner.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PRODUCT_CROSS_SELL',
});

const transformUserRiskRecommendations = <
  T extends
    | FullChurnRiskRecommendationFieldsFragment
    | PartialChurnRiskRecommendationFieldsFragment,
>(
  items: T[],
): RiskRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefCustomerFullChurnRecommendation':
          return transformFullChurnRiskRecommendation(item);
        case 'ReefContractPartialChurnRecommendation':
          return transformPartialChurnRiskRecommendation(item);
        default:
          return null;
      }
    }),
  );

const transformUserGrowthRecommendations = <T extends CrossSellGrowthRecommendationFieldsFragment>(
  items: T[],
): GrowthRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefProductCrossSellRecommendation':
          return transformCrossSellGrowthRecommendation(item);
        default:
          return null;
      }
    }),
  );

type ClientRiskRecommendationsResult =
  GetClientRiskRecommendationsQuery['client']['recommendations'];
type ClientGrowthRecommendationsResult =
  GetClientGrowthRecommendationsQuery['client']['recommendations'];
const isFullChurnRiskRecommendation = (
  item: ClientRiskRecommendationsResult[0],
): item is FullChurnRiskRecommendationFieldsFragment =>
  item.__typename === 'ReefCustomerFullChurnRecommendation';
const isPartialChurnRiskRecommendation = (
  item: ClientRiskRecommendationsResult[0],
): item is PartialChurnRiskRecommendationFieldsFragment =>
  item.__typename === 'ReefContractPartialChurnRecommendation';
const isCrossSellGrowthRecommendation = (
  item: ClientGrowthRecommendationsResult[0],
): item is CrossSellGrowthRecommendationFieldsFragment =>
  item.__typename === 'ReefProductCrossSellRecommendation';
const extractRiskRecommendations = <T extends ClientRiskRecommendationsResult>(items: T) =>
  items.filter(
    (item) => isFullChurnRiskRecommendation(item) || isPartialChurnRiskRecommendation(item),
  );
const extractGrowthRecommendations = <T extends ClientGrowthRecommendationsResult>(items: T) =>
  items.filter((item) => isCrossSellGrowthRecommendation(item));

export const useClientRiskRecommendations = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<RiskRecommendation[], ApolloError> => {
  const clientRecommendationsResult = useResultFromQuery(
    useQuery(GetClientRiskRecommendationsDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (clientRecommendationsResult.state === ResultType.Value) {
      const { recommendations } = clientRecommendationsResult.value.client;
      const riskRecommendations = extractRiskRecommendations(recommendations);
      const value = transformUserRiskRecommendations(riskRecommendations).sort((r1, r2) =>
        differenceInSeconds(r2.createdOn ?? 0, r1.createdOn ?? 0),
      );
      return { state: clientRecommendationsResult.state, value };
    }
    return clientRecommendationsResult;
  }, [clientRecommendationsResult]);
};

export const useMyRecommendations = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<(RiskRecommendation | GrowthRecommendation)[], ApolloError> => {
  const recommendationResult = useResultFromQuery(
    useQuery(GetMyWorkRecommendationsDocument, { skip }),
  );

  return useMemo((): Result<(RiskRecommendation | GrowthRecommendation)[], ApolloError> => {
    if (recommendationResult.state === ResultType.Value) {
      const { recommendations } = recommendationResult.value.self.associated;
      const riskRecommendations = transformUserRiskRecommendations(
        extractRiskRecommendations(recommendations),
      );
      const growthRecommendations = transformUserGrowthRecommendations(
        extractGrowthRecommendations(recommendations),
      );
      return {
        state: recommendationResult.state,
        value: [...riskRecommendations, ...growthRecommendations],
      };
    }
    return recommendationResult;
  }, [recommendationResult]);
};
