import type { RecommendationExpiry, ReefRecommendationStatus } from '../graphql/generated';
import type { PartialUser } from '../hooks/user';
import type { NullablePartial } from '../types/util';
import type { Account, Metric } from './account';
import type { ContractDetails } from './contract';
import type { Objective, Sprint } from './sprint';
import type { UUID } from './uuid';

export interface Recommendation {
  id: UUID;
  title: string;
  expired: RecommendationExpiry;
  status: ReefRecommendationStatus;
  statusUpdatedOn: Date | null;
  playbook: Pick<Objective, 'id' | 'title'>;
  sprint:
    | (Pick<Sprint, 'id' | 'name' | 'start' | 'end'> & {
        creatorId: UUID;
        creatorName: string;
        creatorEmail: string;
        createdAt: Date;
      })
    | null;
  createdOn: Date | null;
  owner: PartialUser | null;
}

export type RiskRecommendationScoreType =
  | 'CONTRACT_USAGE_RATING'
  | 'ACCOUNT_HEALTHSCORE'
  | 'ACCOUNT_FULL_CHURN_RISK'
  | 'PRODUCT_CONSUMPTION_PERCENT'
  | 'UNKNOWN';
export type GrowthRecommendationScoreType = 'PRODUCT_CROSS_SELL_GROWTH' | 'UNKNOWN';
export interface RecommendationMetric extends NullablePartial<Metric> {
  type: RiskRecommendationScoreType | GrowthRecommendationScoreType;
}

export type RiskRecommendationType =
  | 'PARTIAL_CHURN_CONTRACT'
  | 'PARTIAL_CHURN_PRODUCT'
  | 'LOW_ENGAGEMENT'
  | 'PARTIAL_CHURN_PRODUCT_CONSUMPTION'
  | 'FULL_CHURN_CUSTOMER';
export const RISK_TYPE_LABELS: Record<RiskRecommendation['type'], string> = {
  LOW_ENGAGEMENT: 'Low Engagement',
  PARTIAL_CHURN_PRODUCT_CONSUMPTION: 'Low Consumption',
  PARTIAL_CHURN_PRODUCT: 'Downsell',
  PARTIAL_CHURN_CONTRACT: 'Risk at Renewal',
  RISK: 'Risk',
  FULL_CHURN_CUSTOMER: 'Full Churn',
};
interface RiskMetrics {
  originalScore: RecommendationMetric | null;
  currentScore: RecommendationMetric | null;
  originalRelevantArr: number | null;
  currentRelevantArr: number | null;
}

export type PartialAccount = Pick<
  Account,
  'id' | 'name' | 'renewalOwnerName' | 'renewalOwnerEmail' | 'ownerName' | 'ownerEmail'
> & {
  customerHealthscore: Metric['value'];
};

export interface TemporalSequenceAccount {
  original: PartialAccount | null;
  current: PartialAccount;
}

export type PartialContract = Pick<
  ContractDetails,
  'id' | 'name' | 'expiringArr' | 'contractEndDate' | 'contractEndFiscalQuarter' | 'usageRating'
> & { link: string | null; productNames: string[] | null };

export interface TemporalSequenceContract {
  original: PartialContract | null;
  current: PartialContract | null;
}

export interface RiskRecommendation extends Recommendation {
  type: RiskRecommendationType | 'RISK';
  account: TemporalSequenceAccount | PartialAccount;
  contract: TemporalSequenceContract | PartialContract;
  metrics: RiskMetrics | null;
}
export interface RiskRecommendationsSummary {
  numberOfUsers: number;
  numberOfRecommendations: number;
  totalRecommendationsRun: number;
  totalExpiringArr: number;
  totalRelevantArr: number;
}

export interface RiskRecommendationRow {
  id: UUID;
  createdOn: Date | null;
  playbookName: string;
  customerId: UUID;
  customerName: string;
  sprintStarted: boolean;
  customerHealthscore: string | null;
  contractExpiringArr: number | null;
  contractUsageRating: string | null;
  owner: PartialUser | null;
  riskScoreTypeLabel: string | null;
  originalRecommendationScore: RecommendationMetric | null;
  currentRecommendationScore: RecommendationMetric | null;
  originalRelevantArr: number | null;
  currentRelevantArr: number | null;
}
export interface RiskRecommendationFields
  extends Pick<
      Partial<Extract<RiskRecommendation['contract'], TemporalSequenceContract>['current']> &
        Partial<PartialContract>,
      | 'expiringArr'
      | 'link'
      | 'contractEndDate'
      | 'contractEndFiscalQuarter'
      | 'productNames'
      | 'name'
    >,
    Pick<
      Partial<Extract<RiskRecommendation['account'], TemporalSequenceAccount>['current']> &
        Partial<PartialAccount>,
      'renewalOwnerEmail' | 'renewalOwnerName' | 'ownerEmail' | 'ownerName'
    >,
    Pick<RiskMetrics, 'currentScore'>,
    Pick<RiskRecommendation, 'createdOn'> {
  contractExpiringARR: RiskMetrics['currentRelevantArr'];
  customerTotalARR: RiskMetrics['currentRelevantArr'];
}

export type GrowthRecommendationType =
  | 'CUSTOMER_UPGRADE'
  | 'PRODUCT_UPSELL'
  | 'GROWTH_AT_RENEWAL'
  | 'PRODUCT_CROSS_SELL';
export const GROWTH_TYPE_LABELS: Record<GrowthRecommendation['type'], string> = {
  CUSTOMER_UPGRADE: 'Conversion Upgrade',
  PRODUCT_UPSELL: 'Upsell',
  GROWTH_AT_RENEWAL: 'Growth at Renewal',
  PRODUCT_CROSS_SELL: 'Cross-Sell',
};
interface GrowthProduct {
  name: string;
}
interface GrowthMetrics {
  originalScore: RecommendationMetric | null;
  currentScore: RecommendationMetric | null;
  originalPredictedOverage: number | null;
  originalPredictedOverageDate: Date | null; // product_consumption_display overageDate
  currentPredictedOverage: number | null;
  currentPredictedOverageDate: Date | null; // product_consumption_display overageDate
  originalPipeline: number | null;
  currentPipeline: number | null;
  products: GrowthProduct[];
}

export interface GrowthRecommendationsSummary {
  numberOfUsers: number;
  numberOfRecommendations: number;
  totalRecommendationsRun: number;
  totalPriorPipeline: number;
}

export interface GrowthRecommendationRow {
  id: UUID;
  createdOn: Date | null;
  playbookName: string;
  customerId: UUID;
  customerName: string;
  sprintStarted: boolean;
  owner: PartialUser | null;
  originalRecommendationScore: RecommendationMetric | null;
  currentRecommendationScore: RecommendationMetric | null;
  originalPredictedOverage: number | null;
  productNames: string;
  originalPipeline: number | null;
  growthScoreTypeLabel: string;
}

export interface GrowthRecommendation extends Recommendation {
  type: GrowthRecommendationType;
  account: Pick<
    Account,
    | 'id'
    | 'name'
    | 'ownerName'
    | 'ownerEmail'
    | 'secondaryOwnerName'
    | 'secondaryOwnerEmail'
    | 'renewalDate'
    | 'rawPipeline'
    | 'customerStartedOn'
  >;
  metrics: GrowthMetrics;
}
export interface GrowthRecommendationFields
  extends Pick<
      GrowthRecommendation['account'],
      | 'name'
      | 'ownerEmail'
      | 'ownerName'
      | 'secondaryOwnerEmail'
      | 'secondaryOwnerName'
      | 'renewalDate'
      | 'customerStartedOn'
      | 'rawPipeline'
    >,
    Pick<GrowthMetrics, 'currentScore' | 'originalPredictedOverage' | 'products'>,
    Pick<GrowthRecommendation, 'createdOn'> {
  currentPipeline: GrowthRecommendation['account']['rawPipeline'];
  currentPredictedOverage: number | null;
  lastUpsellDate: Date | null;
}

export const isRisk = (r: Recommendation): r is RiskRecommendation =>
  (r as RiskRecommendation).contract != null;
export const isGrowth = (r: Recommendation): r is GrowthRecommendation =>
  (r as GrowthRecommendation).metrics != null;
