import type {
  FullChurnRiskRecommendationFieldsFragment,
  FullChurnRiskRecommendationPreviewFieldsFragment,
  PartialChurnRiskRecommendationFieldsFragment,
  PartialChurnRiskRecommendationPreviewFieldsFragment,
} from '../graphql/generated';
import type { PartialAccount, PartialContract } from '../models/recommendation';
import { asUUID } from '../models/uuid';
import { transformDate } from '../utils';

export const transformBaseAccount = (
  account:
    | FullChurnRiskRecommendationPreviewFieldsFragment['account']['current']
    | PartialChurnRiskRecommendationPreviewFieldsFragment['account']['current']
    | FullChurnRiskRecommendationFieldsFragment['account']['current']
    | PartialChurnRiskRecommendationFieldsFragment['account']['current'],
): PartialAccount => ({
  customerHealthscore: account?.customHealthValue ?? null,
  id: account?.id ?? asUUID(''),
  name: account?.name ?? '',
  ownerEmail: account?.roles.primaryOwner?.email ?? null,
  ownerName: account?.roles.primaryOwner?.name ?? null,
  renewalOwnerEmail: account?.roles.renewalOwner?.email ?? null,
  renewalOwnerName: account?.roles.renewalOwner?.name ?? null,
});

export const transformBaseContract = (
  contract:
    | PartialChurnRiskRecommendationPreviewFieldsFragment['contract']['current']
    | PartialChurnRiskRecommendationFieldsFragment['contract']['current'],
  accountId: string | undefined,
): PartialContract => ({
  contractEndDate: contract?.endDate ? transformDate(contract?.endDate) : null,
  contractEndFiscalQuarter: contract?.endFiscalQuarter ?? '',
  expiringArr: contract?.expiringArr ?? null,
  id: contract?.id ?? asUUID(''),
  link: accountId ? `/customer-details/${accountId}/contracts` : null,
  name: contract?.name ?? null,
  productNames: null,
  usageRating: contract?.usageRating ?? null,
});
